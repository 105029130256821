<template>
  <section class="outContainer">
    <!-- 筛选条件栏 -->
    <div class="search">
      <el-input
        placeholder="设备编号"
        class="each"
        v-model="searchInfo.param.chargingPileId"
      ></el-input>
      <el-input
        placeholder="流水编号"
        class="each"
        v-model="searchInfo.param.orderId"
      ></el-input>
      <el-input
        placeholder="用户手机号"
        class="each"
        v-model="searchInfo.param.payUserPhone"
      ></el-input>
      <el-input
        placeholder="刷卡用户卡号"
        class="each"
        v-model="searchInfo.param.payUserId"
      ></el-input>
      <el-select
        placeholder="用户类型"
        class="each"
        v-model="searchInfo.param.payUserType"
        clearable
      >
        <el-option
          v-for="item in userTypeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        placeholder="订单状态"
        class="each"
        v-model="searchInfo.param.status"
        clearable
      >
        <el-option
          v-for="item in orderStatusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-cascader
        placeholder="所属商户 / 所属小区"
        class="each"
        v-model="unitId"
        clearable
        :options="managerUnitOptions"
        @change="chooseMerchant"
        :props="props"
        v-if="userInfo.roleLevel < 5"
      ></el-cascader>
      <el-select
        placeholder="所属小区"
        class="each"
        v-model="searchInfo.param.unitId"
        clearable
        v-if="userInfo.roleLevel >= 5 && userInfo.roleLevel < 10"
      >
        <el-option
          v-for="item in unitOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-date-picker
        start-placeholder="订单时间"
        range-separator="至"
        end-placeholder="订单时间"
        v-model="orderTime"
        type="daterange"
        :picker-options="pickerOptions"
        @change="chooseOrderTime"
        class="timeSelect each"
      >
      </el-date-picker>
      <el-button
        size="mini"
        type="primary"
        icon="el-icon-search"
        @click="handleSearch('search')"
        >搜索</el-button
      >
      <el-button
        size="mini"
        type="primary"
        icon="el-icon-close"
        @click="handleSearch('clear')"
        >清空搜索条件</el-button
      >
      <el-tooltip
        :disabled="false"
        class="item"
        effect="dark"
        content="选择小区和时间段后才可导出，最多导出180天数据"
        placement="top"
        v-if="userInfo.roleLevel < 10"
      >
        <div style="margin-left: 10px">
          <el-button
            size="mini"
            @click="handleEduce"
            :disabled="searchInfo.param.unitId && orderTime ? false : true"
            >导出</el-button
          >
        </div>
      </el-tooltip>
      <el-tooltip
        class="item"
        effect="dark"
        content="选择时间段后才可导出，最多导出180天数据"
        placement="top"
        v-else
      >
        <div style="margin-left: 10px">
          <el-button
            size="mini"
            @click="handleEduce"
            :disabled="orderTime ? false : true"
            >导出</el-button
          >
        </div>
      </el-tooltip>
    </div>
    <div class="tableContainer">
      <!-- 表格数据 -->
      <el-table :data="tableList" height="100%">
        <af-table-column align="center" label="商户单号" prop="id">
        </af-table-column>
        <af-table-column align="center" label="流水编号" prop="orderId">
          <template slot-scope="scope">
            <p>{{ scope.row.orderId ? scope.row.orderId : '--' }}</p>
          </template>
        </af-table-column>
        <af-table-column align="center" label="用户信息" prop="userName">
          <template slot-scope="scope">
            <p v-if="scope.row.payType == 2">
              卡号：{{ scope.row.payUserId ? scope.row.payUserId : '--' }}
            </p>
            <p>
              用户名：{{ scope.row.payUserName ? scope.row.payUserName : '--' }}
            </p>
            <p>
              电话：{{ scope.row.payUserPhone ? scope.row.payUserPhone : '--' }}
            </p>
          </template>
        </af-table-column>
        <af-table-column align="center" label="支付方式/金额" prop="userType">
          <template slot-scope="scope">
            <p>
              方式：{{ scope.row.payTypeName ? scope.row.payTypeName : '--' }}
            </p>
            <p>金额：{{ scope.row.prePayment ? scope.row.prePayment : 0 }}元</p>
          </template>
        </af-table-column>
        <af-table-column
          align="center"
          label="费用信息"
          prop="feeInfo"
          width="180"
        >
          <template slot-scope="scope">
            <div class="feeContainer">
              <div class="detail">
                <p>
                  <span>电费：</span>
                  <span
                    >{{ scope.row.eleCharge ? scope.row.eleCharge : 0 }}元</span
                  >
                </p>
                <p>
                  <span>服务费：</span>
                  <span
                    >{{
                      scope.row.serviceCharge ? scope.row.serviceCharge : 0
                    }}元</span
                  >
                </p>
                <p>
                  <span>实际收费：</span>
                  <span
                    >{{
                      scope.row.actualPayment ? scope.row.actualPayment : 0
                    }}元</span
                  >
                </p>
              </div>
              <el-tooltip class="item" effect="dark" placement="right">
                <div slot="content" class="content">
                  <p>
                    <span>耗电量：</span>
                    <span> {{ scope.row.power ? scope.row.power : 0 }}度</span>
                  </p>
                  <p>
                    <span>电费：</span>
                    <span
                      >{{
                        scope.row.eleCharge ? scope.row.eleCharge : 0
                      }}元</span
                    >
                  </p>
                  <p>
                    <span>服务费：</span>
                    <span
                      >{{
                        scope.row.serviceCharge ? scope.row.serviceCharge : 0
                      }}元</span
                    >
                  </p>
                  <p>
                    <span>退款金额：</span>
                    <span>
                      {{ scope.row.refund ? scope.row.refund : 0 }}元</span
                    >
                  </p>
                  <p>
                    <span>订单金额：</span>
                    <span>
                      {{
                        scope.row.orderAmount ? scope.row.orderAmount : 0
                      }}元</span
                    >
                  </p>
                  <p>
                    <span>实际收费：</span>
                    <span
                      >{{
                        scope.row.actualPayment ? scope.row.actualPayment : 0
                      }}元</span
                    >
                  </p>
                  <p>
                    <span>优惠金额：</span>
                    <span>
                      {{
                        (
                          (scope.row.orderAmount ? scope.row.orderAmount : 0) -
                          (scope.row.actualPayment
                            ? scope.row.actualPayment
                            : 0)
                        ).toFixed(2)
                      }}元</span
                    >
                  </p>
                  <p>
                    <span>分润金额：</span>
                    <span>
                      {{
                        scope.row.shareProfitAmount
                          ? scope.row.shareProfitAmount
                          : 0
                      }}元</span
                    >
                  </p>
                </div>
                <i class="el-icon-info"></i>
              </el-tooltip>
            </div>
          </template>
        </af-table-column>
        <af-table-column
          align="center"
          label="订单状态"
          prop="orderStatus"
          width="100"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.statusName ? scope.row.statusName : '--' }}</p>
          </template>
        </af-table-column>
        <af-table-column align="center" label="设备信息" prop="deviceId">
          <template slot-scope="scope">
            <p>端口号：{{ scope.row.portNum ? scope.row.portNum : '--' }}</p>
            <p>
              设备号：{{
                scope.row.chargingPileId ? scope.row.chargingPileId : '--'
              }}
            </p>
            <p>场所：{{ scope.row.unitName ? scope.row.unitName : '--' }}</p>
          </template>
        </af-table-column>
        <af-table-column
          align="center"
          label="订单时间"
          prop="createTimeStr"
          width="270"
        >
          <template slot-scope="scope">
            <div class="feeContainer">
              <div class="detail">
                <p>
                  开始充电时间：{{
                    scope.row.startChargingTimeStr
                      ? scope.row.startChargingTimeStr
                      : '--'
                  }}
                </p>
                <p>
                  结束充电时间：{{
                    scope.row.endChargingTimeStr &&
                    scope.row.status != 2 &&
                    scope.row.status != 1
                      ? scope.row.endChargingTimeStr
                      : '--'
                  }}
                </p>
              </div>
              <el-tooltip class="item" effect="dark" placement="top">
                <div slot="content" class="content">
                  <p>
                    订单创建时间：{{
                      scope.row.createTimeStr ? scope.row.createTimeStr : '--'
                    }}
                  </p>
                  <p>
                    订单支付时间：{{
                      scope.row.payTimeStr ? scope.row.payTimeStr : '--'
                    }}
                  </p>
                  <p>
                    开始充电时间：{{
                      scope.row.startChargingTimeStr
                        ? scope.row.startChargingTimeStr
                        : '--'
                    }}
                  </p>
                  <p>
                    结束充电时间：{{
                      scope.row.endChargingTimeStr &&
                      scope.row.status != 2 &&
                      scope.row.status != 1
                        ? scope.row.endChargingTimeStr
                        : '--'
                    }}
                  </p>
                  <p>
                    订单退款时间：{{
                      scope.row.refundTimeStr ? scope.row.refundTimeStr : '--'
                    }}
                  </p>
                  <p>
                    最近更新时间：{{
                      scope.row.updateTimeStr ? scope.row.updateTimeStr : '--'
                    }}
                  </p>
                </div>
                <i class="el-icon-info"></i>
              </el-tooltip>
            </div>
          </template>
        </af-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="120">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleDetail(scope.row)"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 详情 -->
      <el-drawer title="订单详情" :visible.sync="detailDrawer" size="50%">
        <detail
          v-if="detailDrawer"
          @close="handleClose"
          ref="newForm"
          :row="row"
        ></detail>
      </el-drawer>
      <!-- 分页 -->
      <Pagination
        v-bind:child-msg="searchInfo"
        @callFather="callFather"
      ></Pagination>
    </div>
  </section>
</template>

<script>
import Pagination from '@/components/Pagination.vue'
import {
  getNeighbourhoodList,
  getMerchantList,
  getChargingPileOrderList,
  listExportOrder,
} from '@/api/public.js'
import detail from './detail.vue'
import { timeChange } from '@/api/publicFun.js'
const XLSX = require('xlsx-js-style')
import { orderStatusOptions, userTypeOptions } from '@/constants/chargePile.js'
export default {
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
      searchInfo: {
        // 搜索条件
        pageNo: 1,
        pageSize: 10,
        total: 1,
        param: {
          chargingPileId: '',
          orderId: '',
          payUserPhone: '',
          cardNum: '',
          payUserType: '',
          status: '',
          unitId: '',
          startDateTime: '',
          endDateTime: '',
          type: 2,
        },
      },
      tableList: [], // 表格数据列表
      orderTime: '', // 订单时间范围选择绑定数据
      unitOptions: [], // 小区选项
      userTypeOptions: userTypeOptions, // 用户类型
      orderStatusOptions: orderStatusOptions, // 订单状态
      managerUnitOptions: [
        // 所属商户、所属小区选项
        {
          value: 1,
          label: '乐电',
          children: [
            { value: 1, label: '光阳国际' },
            { value: 2, label: '近江八园' },
          ],
        },
      ],
      unitId: '', // 所属商户、小区联动选择框绑定数据
      props: {
        // 所属商户、小区 联动选择框配置
        value: 'id',
        label: 'name',
        lazy: true,
        checkStrictly: true,
        lazyLoad(node, resolve) {
          const { level } = node
          console.log(node, 987)
          setTimeout(() => {
            let data = {
              pageNo: 1,
              pageSize: 1000,
              param: {
                name: null,
                merchantId: node.value,
              },
            }
            if (level == 1) {
              getNeighbourhoodList(data).then(res => {
                // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                let nodes = []
                res.data.list.forEach(element => {
                  nodes.push({
                    id: element.id,
                    name: element.name,
                    leaf: level >= 1,
                    children: null,
                  })
                })
                // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                resolve(nodes)
              })
              console.log(node, 908)
            }
          }, 1000)
        },
      },
      detailDrawer: false, // 控制新增、编辑弹框是否显示
      row: null, // 表格中当前操作的一栏数据
      pickerOptions: {
        // 日期范围选择器，禁用今天及之后的日期
        disabledDate(time) {
          return time.getTime() > Date.now() // 禁用今天之后的日期
        },
      },
    }
  },

  components: {
    Pagination,
    detail,
  },

  computed: {},

  mounted() {
    this.userInfo.roleLevel < 5
      ? this.getMerchantOptions()
      : this.getNieghbourList()
    this.init()
  },

  methods: {
    // 获取订单列表
    async init() {
      await getChargingPileOrderList(this.searchInfo).then(res => {
        if (res.success) {
          this.tableList = res.data ? res.data.list : []
          this.searchInfo.total = res.data.total
          this.tableList.forEach(element => {
            element.startChargingTimeStr = element.startChargingTime
              ? timeChange(element.startChargingTime, 'seconds')
              : '--'
            element.endChargingTimeStr = element.endChargingTime
              ? timeChange(element.endChargingTime, 'seconds')
              : '--'
            element.payTimeStr = element.payTime
              ? timeChange(element.payTime, 'seconds')
              : '--'
            element.refundTimeStr = element.refundTime
              ? timeChange(element.refundTime, 'seconds')
              : '--'
            element.createTimeStr = element.createTime
              ? timeChange(element.createTime, 'seconds')
              : '--'
            element.updateTimeStr = element.updateTime
              ? timeChange(element.updateTime, 'seconds')
              : '--'
          })
        } else {
          this.tableList = []
          this.searchInfo.total = 0
        }
      })
    },
    // 搜索事件
    handleSearch(type) {
      this.searchInfo.pageNo = 1
      if (type == 'clear') {
        this.searchInfo.param = {
          chargingPileId: '',
          orderId: '',
          payUserPhone: '',
          cardNum: '',
          payUserType: '',
          status: '',
          unitId: '',
          startDateTime: '',
          endDateTime: '',
          type: 2,
        }
        this.orderTime = ''
        this.unitId = ''
      }
      this.init()
    },
    // 获取小区列表
    getNieghbourList() {
      let data = {
        pageNo: 1,
        pageSize: 1000,
        param: {
          name: null,
        },
      }
      getNeighbourhoodList(data).then(res => {
        if (res.success) {
          this.unitOptions = res.data.list
        }
      })
    },
    // 获取商户列表
    async getMerchantOptions() {
      let data = {
        pageNo: 1,
        pageSize: 1000,
        param: {
          name: '',
        },
      }
      await getMerchantList(data).then(res => {
        if (res.success) {
          this.managerUnitOptions = res.data.list
          this.managerUnitOptions.forEach(element => {
            element.children = []
          })
        } else {
          this.managerUnitOptions = []
        }
      })
    },
    // 选择商户、小区
    chooseMerchant(e) {
      this.searchInfo.param.merchantId = e[0] ? e[0] : null
      this.searchInfo.param.unitId = e[1] ? e[1] : null
    },
    // 选择订单时间范围
    chooseOrderTime(e) {
      if (e) {
        this.searchInfo.param.startDateTime = timeChange(e[0], 'day')
        this.searchInfo.param.endDateTime = timeChange(e[1], 'day')
      } else {
        this.searchInfo.param.startDateTime = ''
        this.searchInfo.param.endDateTime = ''
      }
    },
    // 打开详情弹框
    handleDetail(row) {
      this.row = row
      this.detailDrawer = true
    },
    // 关闭弹框
    handleClose() {
      this.row = null
      this.detailDrawer = false
    },
    // 导出
    handleEduce() {
      this.$confirm('确认导出吗？').then(async () => {
        const loading = this.$loading({
          lock: true,
          text: '导出中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
        })
        await listExportOrder(this.searchInfo).then(res => {
          if (res.success) {
            if (res.data && res.data.length > 0) {
              let datalist = res.data
              const book = XLSX.utils.book_new()
              let data = [
                [
                  '流水编号',
                  '用户信息',
                  '支付方式',
                  '支付金额（元）',
                  '耗电量（度）',
                  '电费（元）',
                  '服务费（元）',
                  '退款金额（元）',
                  '订单金额（元）',
                  '实际收费（元）',
                  '分润金额（元）',
                  '订单状态',
                  '设备信息',
                  '创建时间',
                  '支付时间',
                  '开始充电时间',
                  '结束充电时间',
                  '退款时间',
                  '更新时间',
                ],
              ]
              datalist.forEach(element => {
                let each = []
                each.push(element.orderId ? element.orderId : '--')
                let userInfo = `${
                  element.payUserName ? element.payUserName : '暂无'
                } - ${element.payUserPhone}`
                each.push(userInfo)
                each.push(element.payTypeName ? element.payTypeName : '--')
                each.push(element.prePayment ? element.prePayment : 0)
                each.push(element.power ? element.power : 0)
                each.push(element.eleCharge ? element.eleCharge : 0)
                each.push(element.serviceCharge ? element.serviceCharge : 0)
                each.push(element.refund ? element.refund : 0)
                each.push(element.orderAmount ? element.orderAmount : 0)
                each.push(element.actualPayment ? element.actualPayment : 0)
                each.push(
                  element.shareProfitAmount ? element.shareProfitAmount : 0
                )
                each.push(element.statusName ? element.statusName : '--')
                each.push(
                  element.chargingPileId + ' - ' + element.portNum + '号'
                )
                let createTimeStr = element.createTime
                  ? timeChange(element.createTime, 'seconds')
                  : '--'
                let payTimeStr = element.payTime
                  ? timeChange(element.payTime, 'seconds')
                  : '--'
                let startChargingTimeStr = element.startChargingTime
                  ? timeChange(element.startChargingTime, 'seconds')
                  : '--'
                let endChargingTimeStr = element.endChargingTime
                  ? timeChange(element.endChargingTime, 'seconds')
                  : '--'
                let refundTimeStr = element.refundTime
                  ? timeChange(element.refundTime, 'seconds')
                  : '--'
                let updateTimeStr = element.updateTime
                  ? timeChange(element.updateTime, 'seconds')
                  : '--'
                each.push(createTimeStr)
                each.push(payTimeStr)
                each.push(startChargingTimeStr)
                each.push(endChargingTimeStr)
                each.push(refundTimeStr)
                each.push(updateTimeStr)
                data.push(each)
              })
              // excel导出
              const sheet = XLSX.utils.aoa_to_sheet(data)
              // 设置列宽为自适应
              sheet['!cols'] = [
                { wch: 20 },
                { wch: 22 },
                { wch: 18 },
                { wch: 18 },
                { wch: 18 },
                { wch: 18 },
                { wch: 18 },
                { wch: 18 },
                { wch: 18 },
                { wch: 18 },
                { wch: 18 },
                { wch: 18 },
                { wch: 18 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
              ]
              sheet['!rows'] = [] // 行高
              // 设置单元格样式
              for (let row = 0; row < data.length; row++) {
                for (let col = 0; col < data[row].length; col++) {
                  sheet['!rows'].push({ hpx: 28 }) // 行高
                  const cellRef = XLSX.utils.encode_cell({ c: col, r: row })
                  console.log('单元格', cellRef)
                  sheet[cellRef].s = {
                    alignment: {
                      horizontal: 'center', // 水平居中
                      vertical: 'center', // 垂直居中
                    },
                    font: {
                      bold: true, // 加粗
                    },
                  }
                }
              }
              XLSX.utils.book_append_sheet(book, sheet, 'sheet1') // 生成sheet
              XLSX.writeFile(book, `订单统计.xlsx`) // 导出
            } else {
              this.$message({
                message: '查询不到数据',
                type: 'fail',
              })
            }
          } else {
            this.$message({
              message: res.msg,
              type: 'fail',
            })
          }
        })
        loading.close()
      })
    },
    // 分页操作事件
    callFather(parm) {
      this.searchInfo.pageNo = parm.currentPage
      this.init()
    },
  },
}
</script>
<style scoped lang="scss">
.feeContainer {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  .el-icon-info {
    cursor: pointer;
    margin-left: 5px;
  }
}
.content {
  p {
    line-height: 20px;
    font-size: 14px;
  }
}
.outContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  .tableContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
/deep/ .el-drawer__header {
  margin-bottom: 20px !important;
}
</style>
