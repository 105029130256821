/**
 * 充电桩静态资源配置
 */

// 订单状态
export const orderStatusOptions = [
    {value:1,label:'未支付'},
    {value:2,label:'已支付'},
    {value:3,label:'退款中'},
    {value:4,label:'退款失败'},
    {value:5,label:'已完成'},
    {value:6,label:'支付过期'},
];
// 订单类型
export const orderTypeOptions = [
    {value:1,label:'电瓶车充电订单'},
    {value:2,label:'新能源充电订单'},
];
// 用户类型
export const userTypeOptions = [
    {value:1,label:'微信用户'},
    {value:2,label:'刷卡用户'},
];