<template>
  <section class="container">
    <div class="detail">
      <p> <span>订单编号</span> <b>{{ row.orderId ? row.orderId : '--' }}</b> </p>
      <p> <span>订单状态</span> <b>{{ row.statusName ? row.statusName : '--' }}</b> </p>
      <p> <span>预付金额</span> <b>{{ row.prePayment ? row.prePayment : 0 }}元</b> </p>
      <p> <span>开始时间</span> <b>{{ row.startChargingTimeStr ? row.startChargingTimeStr : '--' }}</b> </p>
      <p> <span>结束时间</span> <b>{{ row.endChargingTimeStr && row.status != 2 && row.status != 1 ? row.endChargingTimeStr : '--' }}</b> </p>
      <p> <span>耗电量</span> <b>{{ row.power ? row.power : 0 }}度</b> </p>
      <p> <span>设备号</span> <b>{{ row.chargingPileId ? row.chargingPileId : '--' }}</b> </p>
      <p> <span>端口号</span> <b>{{ row.portNum ? row.portNum : '--' }}号</b> </p>
      <p v-if="orderInfo.status == 2 && orderInfo.chargingPilePortVO"> <span>端口状态</span> <b>{{ orderInfo.chargingPilePortVO.portStatusName ? orderInfo.chargingPilePortVO.portStatusName : '--' }}</b> </p>
      <p> <span>场所名称</span> <b>{{ orderInfo.unitName ? orderInfo.unitName : '--' }}</b></p>
    </div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>费率</span>
      </div>
      <div v-for="(item,index) in rateRules" :key="index">
        {{ item.startTime + '-' + item.endTime + '时&nbsp;&nbsp;&nbsp;电费：' + item.ele + '元/度&nbsp;&nbsp;&nbsp;服务费：' + item.fee + '元/度' }}
      </div>
      <p v-if="rateRules.length == 0">暂无费率信息</p>
    </el-card>
    <div class="charts">
      <el-tabs v-model="activeName">
        <el-tab-pane label="折线图" name="first">
          <div id="power"></div>
          <div id="voltage"></div>
          <div id="electricCurrent"></div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </section>
</template>

<script>
import * as echarts from 'echarts';
import { getChargingPileOrderDetail } from '@/api/public.js';
import { timeChange } from '@/api/publicFun.js';
let that;
export default {
  props:['row'],
  data() {
    return {
      activeName:'first',
      orderInfo:{},
      benefitInfo:[], // 分润信息
      powerTime:[], // 图表时间数据数组
      powerData:[], // 功率图表数据数据
      voltageData:[], // 电压图表数据数据
      electricData:[], // 电流图表数据数据
      tickEnergyData:[], // 本次心跳用电量数据数组
      totalEnergyData:[], // 总用电量数据数组
      tickMoneyData:[], // 本次心跳金额数据数组
      totalMoneyData:[], // 总金额数据数组
      rateRules:[], // 当前订单使用的费率信息
    }
  },

  components: {},

  computed: {},

  mounted() {
    that = this;
    this.orderInfo = {...this.row};
    this.init();
    this.$nextTick(()=>{
      this.power();
      this.voltage();
      this.electric();
    })
  },

  methods: {
    async init(){
      await getChargingPileOrderDetail({param:this.row.id}).then(res=>{
        if(res.success){
          this.orderInfo = res.data;
          this.rateRules = this.orderInfo.rates ? JSON.parse(this.orderInfo.rates).rates : [];
          if(this.orderInfo.chargingPileDeviceStatusList.length > 0){
            this.orderInfo.chargingPileDeviceStatusList.forEach(element => {
              element.createTime = timeChange(element.createTime,'seconds');
              this.powerTime.push(element.createTime);
              this.powerData.push(element.power ? element.power : 0);
              this.voltageData.push(element.voltage ? element.voltage : 0);
              this.electricData.push(element.current ? element.current : 0);
              this.tickEnergyData.push(element.tickEnergy ? element.tickEnergy : 0);
              this.totalEnergyData.push(element.totalEnergy ? element.totalEnergy : 0);
              this.tickMoneyData.push(element.tickMoney ? element.tickMoney : 0);
              this.totalMoneyData.push(element.totalMoney ? element.totalMoney : 0);
            });
          }else{
            this.powerTime = [];
            this.powerData = [];
            this.voltageTime = [];
            this.voltageData = [];
            this.electricTime = [];
            this.electricData = [];
            this.tickEnergyData = [];
            this.totalEnergyData = [];
            this.tickMoneyData = [];
            this.totalMoneyData = [];
          }
        }
      })
    },
    // 功率、电压、电流趋势统计折线图
    power(){
      var powerChartDom = document.getElementById('power');
      let powerChart = echarts.init(powerChartDom);
      setTimeout(()=>{
        var powerOption = {
          title: {
            text: '电力趋势图',
          },
          tooltip: { // 鼠标悬浮时显示当下数据详情
            trigger: 'axis'
          },
          legend: {
            data: ['功率(W)', '电压(V)', '电流(A)'],
            show:true,
          },
          xAxis: {
            type: 'category',
            data: that.powerTime
          },
          yAxis: [
            {
              name: '功率、电压',
              type: 'value'
            },
            {
              name: '电流',
              type: 'value',
            }
          ],
          series: [
            {
              name:'功率(W)',
              data: this.powerData,
              type: 'line',
              smooth: true,
            },
            {
              name:'电压(V)',
              data: this.voltageData,
              type: 'line',
              smooth: true,
            },
            {
              name:'电流(A)',
              data: this.electricData,
              type: 'line',
              smooth: true,
              yAxisIndex: 1,
            }
          ]
        };
        powerOption && powerChart.setOption(powerOption);
      },500)
    },
    // 总金额、总电量趋势
    voltage(){
      var voltageChartDom = document.getElementById('voltage');
      let voltageChart = echarts.init(voltageChartDom);
      setTimeout(()=>{
        var voltageOption = {
          title: {
            text: '总金额、电量趋势',
          },
          tooltip: { // 鼠标悬浮时显示当下数据详情
            trigger: 'axis'
          },
          legend: {
            data: [ '总金额','总用电量'],
            show:true,
          },
          xAxis: {
            type: 'category',
            data: that.powerTime
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              name:'总金额',
              data: this.totalMoneyData,
              type: 'line',
              smooth: true,
            },
            {
              name:'总用电量',
              data: this.totalEnergyData,
              type: 'line',
              smooth: true,
            }
          ]
        };
        voltageOption && voltageChart.setOption(voltageOption);
      },500)
    },
    // 电流统计
    electric(){
      var electricChartDom = document.getElementById('electricCurrent');
      let electricChart = echarts.init(electricChartDom);
      setTimeout(()=>{
        var electricOption = {
          title: {
            text: '心跳金额、电量趋势',
          },
          tooltip: { // 鼠标悬浮时显示当下数据详情
            trigger: 'axis'
          },
          legend: {
            data: [ '本次心跳金额','本次心跳电量'],
            show:true,
          },
          xAxis: {
            type: 'category',
            data: that.powerTime
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              name:'本次心跳金额',
              data: this.tickMoneyData,
              type: 'line',
              smooth: true,
            },
            {
              name:'本次心跳电量',
              data: this.tickEnergyData,
              type: 'line',
              smooth: true,
            }
          ]
        };
        electricOption && electricChart.setOption(electricOption);
      },500)
    },
  },
};
</script>
<style scoped lang="scss">
.container{
  height: 100%;
  display: flex;
  flex-direction: column;
}
/deep/.box-card{
  width: 90%;
  margin:0 auto 20px;
}
#power{
  margin-top: 40px;
}
.detail{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 50px;
  font-size: 14px;
  box-sizing: border-box;
  h3{
      width: 100%;
      height: 70px;
      line-height: 70px;
      text-align: left;
  }
  p{
      line-height: 16px;
      box-sizing: border-box;
      display: flex;
      width: 50%;
      span{
          display: inline-block;
          background: #fafafa;
          border:solid #f0f0f0 1px;
          width: 120px;
          box-sizing: border-box;
          height: 40px;
          line-height: 40px;
          box-sizing: border-box;
      }
      b{
           display: inline-block;
          background: #fff;
           border:solid #f0f0f0 1px;
           box-sizing: border-box;
           height: 40px;
           line-height: 40px;
           flex: 1;
       }
  }
  .totalWidth{
    width: 100%;
  }
}
.charts{
  width: 90%;
  margin: 0 auto;
  height: 100%;
  flex: 1;
  div{
      width: 100%;
      height: 350px;
      margin-bottom: 30px;
  }
}
/deep/ .el-tabs__content{
  overflow: auto !important;
}
.benefit{
    display: flex;
    flex-direction: column;
    div{
      width: 100%;
      box-sizing: border-box;
      display: flex;
      margin-bottom: 0 !important;
      height: 40px!important;
      &:nth-child(1){
        background: #fafafa;
      }
      span{
        display: inline-block;
        width: 25%;
        border: solid 1px #f0f0f0;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
      }
    }
  }
</style>
